section.state-table {
    padding: 5rem 0;
}
section.state-table h2 {
    color: #FFF;
    font-family: Poppins;
    font-size: 30.188px;
    font-style: normal;
    font-weight: 600;
    line-height: 33.206px;
    margin: 5rem 0 2rem;
}
section.state-table ul {
    display: flex;
    padding: 0;
}
section.state-table ul li {
    list-style: none;
    padding-right: 20px;
}
section.state-table ul li a {
    color: #545454;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18.113px;
}
section.state-table ul li .active {
    color: #FFF;
    border-bottom: 2px solid#fff;
}
section.state-table table{
    border:none;
    background-color:transparent;
    margin-top: 4rem;
}
section.state-table table tr{
    border:none;
    background-color:transparent;
}
section.state-table table tr th {
    border: none;
    background-color: transparent;
    color: #FFF;
    text-align: left;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 15.094px;
    padding-bottom: 30px;
}
section.state-table table tr td {
    border: none;
    background-color: transparent;
    color: #FFF;
    font-family: 'Poppins';
    font-size: 12.075px;
    font-style: normal;
    font-weight: 600;
    line-height: 18.113px;
    padding: 15px;
    vertical-align: middle;
}
section.state-table table tr td .table_img img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    display: block;
    border-radius: 5px;
}
section.state-table table tr td .user_name p {
    margin: 0 0px 0 10px;
}
section.state-table table tr td .green {
    color: #34C77B;
}
section.state-table table tr td .red {
    color: #E62929;
}
@media (max-width: 767px) {
    .stats_table {
        overflow-x: scroll;
    }
}