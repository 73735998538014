.nftbanner {
    padding: 12rem 0;
    background:url(../../assets/images/createnft-bg.png)no-repeat;
    background-size: cover;
}
section.nftbanner h3 {
    color: #FFF;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
}
.nft-form form {
    text-align: left;
    width: 70%;
    margin: 0 auto;
    padding: 4rem;
}
.nft-form form small span {
    color: #EB5757;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    margin-right: 10px;
}
.nft-form form small p {
    color: #FFF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    display: inline-block;
    margin-left: 10px;
}
.nft-form form small h5 {
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-top: 20px;
}
.nft-form form small.form-text p {
    font-size: 12px;
    margin: 0;
}
.choose_img {
    border-radius: 8px;
    border: 3px dashed #CCC;
    width: 350px;
    height: 260px;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    display: flex;
    position: relative;
}
.choose_img input {
    opacity: 0;
    width: 350px;
    height: 260px;
    cursor: pointer;
}
.choose_img img {
    position: absolute;
    top: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}
.preview_img img {
    position: absolute;
    top: 10px;
    width: 330px;
    height: 243px;
    object-fit: cover;
    margin: 0 auto;
    left: 10px;
    border-radius: 10px;
}
.preview_img button {
    background: none;
    border: none;
    position: absolute;
    top: 8px;
}
.nft-form form label {
    color: #FFF;
    font-family: 'Poppins';
    font-size: 18px;
    font-style: normal;
    line-height: 24px;
}
.nft-form form p {
    color: #FFF;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}
.nft-form form input {
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.12);
    background: transparent;
    padding: 12.5px 13px;
    outline: 0;
    color: #FFF !important;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    appearance:none;
}
.nft-form form .form-control:focus {
    box-shadow: none !important;
    background: none;
    border: 1px solid rgba(255, 255, 255, 0.12);
    
}
.nft-form form ::placeholder{color:#fff;}
.nft-form form textarea {
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.12);
    background: transparent;
    padding: 12.5px 13px;
    outline: 0;
    color: #FFF !important;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.listing {
    display: flex;
}
.listing .list_text h4 {
    color: #FFF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 0;
}
.list_img {
    margin-right: 15px;
}
.nft-form form .form-check-input:checked[type=checkbox] {
    background-repeat: no-repeat;
    width: 55px !important;
}
.nft-form form .form-switch .form-check-input{
    background-repeat: no-repeat;
    width: 55px !important;
}
.nft-form form .form-check-input:focus{box-shadow: none;}
.nft-form form .form-switch .form-check-input{margin-left:0;filter: contrast(0.1);}
.nft-form form .form-switch .form-check-input:checked {
   filter: contrast(1) !important;
    background-color: #8d1832;
    border-color: #8d1832;
}
.nft-form form .pop-btn li {
    list-style: none;
}
.nft-form form .pop-btn li button {
    padding: 5px;
    width: 65px;
}
.coin {position: relative;}
.coin input {
    padding-left:60px !important;
}
.coin img {
    position: absolute;
    bottom: 10px;
    left: 15px;
}
button.create_btn {
    border-radius: 8px;
    background-color: #8D1832 !important;
    border: none;
    padding: 12px 50px;
    text-align: center;
    display: block;
    margin: 30px auto 0;
}
.common_modal .modal-dialog {
    max-width: 580px;
}
.common_modal .modal-dialog .modal-title.h4 {
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    padding: 20px;
}
.common_modal form {
    text-align: left;
}
.common_modal p {
    color: #FFF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
}
.common_modal form .form-label {
    color: #FFF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}
.common_modal form input {
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.12);
    background-color: transparent;
    height: 100px;
    display: flex;
    color: #FFF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom:20px;
}
.common_modal form .form-control:focus {
    color: #fff !important;
    background: transparent;
    box-shadow: none !important;
    border: 1px solid rgba(255, 255, 255, 0.12);
}
.common_modal .modal-dialog .modal-content .modal-body a {
    margin: 30px 0;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.12);
    background: transparent;
}
.common_modal .modal-dialog .modal-content .modal-body a:hover{
    background: #8D1832;
}
.common_modal .modal-dialog .modal-content .modal-body button.save {
    border-radius: 8px;
    background: #8D1832;
    border: none;
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    line-height: normal;
    padding: 12px;
    display: block;
    width: 100%;
    margin: 0 0 20px;
}
.common_modal .modal-dialog .modal-content .modal-body button.btn.btn-outline-danger {
    padding: 13px 20px;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.12);
    background: transparent;
    margin: 0 10px;
}
.common_modal .modal-dialog .modal-content .modal-body button:focus{border: 1px solid rgba(255, 255, 255, 0.12) !important;}
.level-modal .addRemove p {
    padding: 10px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;

}
body .common_modal .modal-dialog{
    max-width: 600px;
}
.nft-form form .form-select {
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.12);
    background: transparent;
    padding: 12.5px 13px;
    outline: 0;
    color: #FFF !important;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    appearance: auto;
}
.product_name img {
    width: 37px;
    height: 37px;
    border-radius: 50%;
    object-fit: cover;
}
.product_name {
    display: flex;
}
.level_data ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
}
.level_data ul li {
    background: #333;
    padding: 10px;
    width: 45%;
    margin-right: 10px;
    list-style: none;
    color: #fff;
    border-radius: 5px;
}
body .create_collection .modal-title.h4 {
    text-align: left;
    font-size: 28px !important;
    font-family: 'Poppins';
}
body .create_collection .modal-header p{
    padding: 0 20px;
}
body .create_collection .modal-body img{
    width:auto !important;
    z-index:1;
}
body .create_collection .modal-body {
    padding: 0 25px;
}
body .create_collection .choose_img {
    width: 100%;
    height: 155px;
}
body .create_collection .choose_img input {
    opacity: 0;
    width: 200px;
    height: 120px;
    cursor: pointer;
}
body .create_collection .modal-body img {
    width: auto !important;
    z-index: 1;
    border: 1px dashed#fff;
    padding: 20px;
}
body .create_collection .choose_img input {
    opacity: 0;
    width: 200px;
    height: 120px;
    cursor: pointer;
    z-index: 99;
}
body .create_collection .choose_img {
    width: 100%;
    height: 155px;
    border: 1px solid;
}
body .create_collection .modal-body .preview_img img {
    width: 94px !important;
    padding: 0;
    border: none;
    position: absolute;
    left: 41%;
    top: 28px;
    height: 100px !important;
}
body .create_collection .modal-body .preview_img button {
    background: none;
    border: none;
    position: absolute;
    top: 26px;
    z-index: 999999;
    margin-right: 0;
    padding:0 4px;
}
body .create_collection .modal-body .preview_img {
    position: absolute;
    top: 0;
    left: 41%;
}
body .create_collection .modal-body .preview_img button svg {
    width: 20px;
}
body .create_collection .modal-body input {
    height: 52px;
}
body .create_collection .modal-body input::placeholder{
    color:#fff;
    opacity:0.5;
}
body .create_collection .modal-body button {
    display: block;
    width: 100%;
    padding: 15px 25px;
}
body .create_collection .modal-body .form-select {
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.12);
    background-color: transparent;
    height: 53px;
    color: #FFF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 20px;
}
.create_collection {
    z-index: 9999;
}
.create_collectionbtn {
    border-radius: 8px;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding: 12px 40px;
    display: inline-block;
    background-color: #8d1832 !important;
    border: none !important;
}


option{background-color:#000;color: #fff;}
@media (max-width: 767px) {
.nft-form form {
    width: 100%;
    padding: 1rem;
}
}