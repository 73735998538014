section.collection-banner {
   padding: 10rem 0 0;
   min-height: 430px;
   overflow: hidden;
}
.filtters.search ul {
   align-items: center;
}
.filtters.search ul li select {
   padding: 10px;
}
.filtters.search {
   display: flex;
}
.form-control:focus{box-shadow: none !important;}
.form-select:focus{box-shadow: none !important;}

.nav.nav-tabs button {
   color: #FFF;
   font-family: 'Poppins';
   font-size: 15px;
   font-style: normal;
   line-height: normal;
   opacity: 0.5;
}
.nav.nav-tabs button.active {
   border: none;
   background: none;
   color: #FFF;
   opacity: 1;
}
.nav.nav-tabs button:hover {
   border: none;
   background: none;
   color: #FFF;
   opacity: 1;
}
button:focus{border:none !important;}
.nav-tabs .nav-link{border:none !important;}
.nav.nav-tabs {
   border: none;
   background-color: #36454f;
   margin-bottom: 0 !important;
   padding: 10px 0;
}
section.collection-banner .adit-profile {
   position: absolute;
   left: 0;
   top: 100px;
   display: flex;
   align-items: center;
}
section.collection-banner .banner-heading a{
   position: inherit !important;
}
section.collection-banner .adit-profile .user-profile img {
   width: 120px;
}
section.collection-banner .adit-profile .user-profile {
   height: auto;
   object-fit: cover;
   border-radius: 5px !important;
   border: 2px solid#8d1832;
   background: #fff;
   text-align: center;
   padding: 0;
   position: relative;
}
section.collection-banner .adit-profile .user-profile button {
   background: none;
   border: none;
   padding: 0;
   position: absolute;
   right: 1px;
   font-size: 24px;
   color: #fff;
   margin: 0;
   top: 4px;
}
section.collection-banner .profile-text {
   padding-left: 20px;
}
body .edit_profile input {
   height: auto;
   padding: 12px;
}
section.collection-banner .profile-text span strong {
   font-weight: 500;
   font-size: 22px;
}
section.collection-banner .profile-text span {
   color: #FFF;
   font-family: 'Poppins';
   font-size: 15px;
   font-style: normal;
   line-height: normal;
   opacity: 1;
   margin: 0 0 10px;
}
body .edit_profile textarea {
   border-radius: 10px;
   border: 1px solid rgba(255, 255, 255, 0.12);
   background-color: transparent;
   height: 100px;
   display: flex;
   color: #FFF;
   font-family: Poppins;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
   margin-bottom: 20px;
}
body .edit_profile form {
   padding-bottom: 0;
}
body .edit_profile .modal-footer {
   border: none;
   text-align: center;
   justify-content: center;
   padding-top: 0;
}
body .edit_profile .modal-footer button.btn.btn-primary {
   border-radius: 8px;
   border: 1px solid #8d1832;
   color: #FFF;
   text-align: center;
   font-family: Poppins;
   font-size: 16px;
   font-style: normal;
   font-weight: 500;
   line-height: normal;
   padding: 10px 30px;
   display: inline-block;
   background: #8d1832;
   outline: 0;
}
button.btn.btn-secondary {
   border-radius: 8px;
   border: 1px solid #6c757d;
   color: #FFF;
   text-align: center;
   font-family: Poppins;
   font-size: 16px;
   font-style: normal;
   font-weight: 500;
   line-height: normal;
   padding: 10px 30px;
   display: inline-block;
}
.edit_profile .choose_img {
   border-radius: 8px;
   border: 3px dashed #CCC;
   width: 150px;
   height: 150px;
   justify-content: center;
   align-items: center;
   display: block;
   position: relative;
   z-index: 9;
   margin: 30px auto;
   overflow: hidden;
}
body .edit_profile .preview_img img {
   top: -17px;
   position: absolute;
   left: 70px;
   width: 100% !important;
   margin: 0 !important;
   height: 200px !important;
}
.edit_profile .preview_img {
   position: relative;
}
.edit_profile .choose_img svg {
   position: absolute;
   top: 15px;
   right: 12px;
   color: #fff;
   font-size: 25px;
}
.edit_profile .choose_img input {
   z-index: 999;
   position: relative;
   width: 60px;
   height: 64px;
   margin: 0 0 0 auto;
   cursor: pointer;
   padding: 30px;
}
.edit_profile .choose_img svg {
   position: absolute;
   top: 15px;
   right: 12px;
   color: #fff;
   font-size: 25px;
   z-index: 1;
}
.name_controller input {
   height: auto !important;
}
@media (max-width:767px) {
   section.fillter_nav .nav.nav-tabs {
      flex-wrap: nowrap;
      overflow-x: scroll;
  }
  section.fillter_nav .nav.nav-tabs button {
   display: block;
   width: 117px;
}
body section.fillter_nav .col-md-3 {
   order: inherit;
   width: 100%;
}
.search {
   position: relative;
   left: -6px;
}
section.fillter_nav .filtters.search ul li:first-child {
   display: none;
}
section.fillter_nav .filtters.search ul li select{margin:0;}
section.fillter_nav .filtters.search ul li{padding-left:0;padding-top:0;}
section.fillter_nav .search ul li:first-child{display: block;}
.w-flex {
   width: 70%;
   max-width: 70%;
   flex: 70%;
}
.flex-box {
   width: 30%;
   max-width: 30%;
   flex: 30%;
}
section.collection-banner img {
   left: 20px;
}

}
section.collection-banner .profile-text p {
   color: #fff;
}
section.collection-banner .profile-text p strong {
   font-weight: 500;
   font-size: 18px;
   color: #fff;
}
section.collection-banner .profile-text p {
   color: #fff;
}
.Toastify .Toastify__toast {
   right: 0 !important;
   transform: inherit !important;
}
.Toastify__toast-container--top-center {
   right: 18px !important;
   transform: inherit !important;
   left: inherit !important;
}
.Toastify__toast{position:absolute !important;}
body .common_modal form input{height:auto;}
body .common_modal form .form-control:disabled {
   background-color: transparent;
   opacity: 1;
   cursor: no-drop;
   color: #606060;
}
.edit_profile.common_modal.modal.show {
   z-index: 9999999;
}
#default_key{
   cursor: not-allowed;
}
.user-profile button {
   background-color: #8d1832 !important;
   width: 30px;
   height: 30px;
   border-radius: 50%;
   line-height: 10px;
   object-fit: contain;
   margin-top: 10px;
}
.defolt_icon {
   width: 110px !important;
   padding: 30px;
}
.user-profile button svg {
   width: 18px;
}
section.collection-banner .adit-profile .user-profile .show_preview {
   width: 150px;
}