section.collection-banner {
    padding: 10rem 0;
    background:url(../../assets/images/collection-bg.png)no-repeat;
    background-size: cover;
}
section.collection-banner .banner-heading h1 {
    color: #FFF;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
    padding: 30px;
}
section.collection-banner .banner-heading a {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
}
section.collection-banner .banner-heading a {
    display: inline-block;
    position: absolute !important;
    top: 0;
    left: 0;
    color: #FFF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
}
section.collection-banner .banner-heading a svg {
    margin-right: 10px;
}
section.fillter_nav {
    background: #36454F;
    padding: 15px;
}
section.fillter_nav .filtters ul {
    display: flex;
    padding: 0;
    margin-bottom: 0;
}
section.fillter_nav .filtters ul li {
    list-style: none;
    padding: 10px;
}
section.fillter_nav .filtters ul li a {
    color: #FFF;
    text-align: center;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
section.fillter_nav .filtters ul li a span {
    color: #1e9f1e;
    font-size: 30px;
    line-height: 0;
    margin-right: 7px;
}
section.fillter_nav .search ul {
    display: flex;
    justify-content: center;
    padding:0;
    margin-bottom: 0;
}
section.fillter_nav .search ul li{list-style: none;position: relative;}
section.fillter_nav .search ul li input {
    border-radius: 10px;
    border: 1px solid #FFF;
    background: #36454F;
    color: #FFF;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 500px;
    height: 50px;
    font-family: 'Poppins';
    padding: 0 40px;
}
section.fillter_nav .search ul li input::placeholder{
    color: #FFF;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.5;
    font-family: 'Poppins';
}
section.fillter_nav .search ul li svg {
    position: absolute;
    top: 18px;
    left: 10px;
    opacity: 0.5;
    color: #FFF;
}
section.fillter_nav .search ul li select {
    border-radius: 10px;
    border: 1px solid #FFF;
    background: #36454F;
    height: 50px;
    margin-left: 12px;
    color: #FFF;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    appearance: auto;
}
section.fillter_nav .switch ul {
    display: flex;
    justify-content: flex-end;
    padding:0;
    margin-bottom: 0;
}
section.fillter_nav .switch ul li {
    list-style: none;
    padding: 10px;
}
section.collection-wrapper section.actions{background: none;}
section.collection-wrapper {
    overflow: hidden;
    padding-right: 5rem;
}
.sidebar {
    background-color: #8d1832;
    height: 100vh;
    width: 85%;
}
.sidebar .accordion-item {
    background: transparent;
    border: none;
}
.sidebar .accordion-header {
    margin-bottom: 0;
    background-color: transparent;
}
.sidebar .accordion-item button {
    border: none;
    background-color: transparent !important;
}
.sidebar .accordion {
    padding: 30px 40px;
}
.sidebar .accordion-item button {
    border: none;
    background-color: transparent !important;
    color: #FFF;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    line-height: 13.485px;
}
.sidebar .accordion-item button::after {
    filter: invert(1);
}
.sidebar .accordion-body {
    text-align: left;
    padding: 10px 25px;
    border-bottom: 1px solid#fff;
}
.sidebar .accordion-body .form-check-inline {
    display: block;
    margin-right: 1rem;
    color: #FFF;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
}
.sidebar .accordion-body label {
    display: inline-block;
    margin-left: 10px;
}
.sidebar .form-check .form-check-input {
    background-color: transparent;
}
.sidebar .form-check-input:focus {
    border-color: #fff !important;
    outline: 0;
    box-shadow: none;
}
.sidebar .form-check-input:checked{ border-color: #fff !important;}
.sidebar .accordion-button:focus{box-shadow: none !important;}
.sidebar .accordion-button:not(.collapsed){box-shadow: none !important;}
.sidebar .accordion-body ul.price {
    display: flex;
    padding: 0;
}
.sidebar .accordion-body ul.price li{list-style: none;}
.sidebar .accordion-body ul.price li select {
    padding: 7px;
    width: 70px;
    border-radius: 4px;
    background: #480F0F;
    color: #FFF;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    appearance: auto;
    border: none;
    text-align: center;
    margin-right: 10px;
}
.sidebar .accordion-body ul.price li input {
    padding: 7px;
    width: 70px;
    border-radius: 4px;
    background: #480F0F;
    margin-right: 10px;
    outline:0;
    color: #FFF;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    appearance: auto;
    border: none;
    text-align: center;
}
.sidebar .accordion-body ul.price li input::placeholder{
    color: #FFF;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    appearance: auto;
    border: none;
    text-align: center;
}
.sidebar .accordion-body .form-control:focus{box-shadow: none;border-color: #fff;}
.sidebar .accordion-body ul.price li span{
    list-style: none;
    color: #FFF;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    height: auto;
    line-height: 32px;
    margin-right:10px;
}
.sidebar .accordion-item button.apply {
    border-radius: 6px;
    background-color: #480F0F !important;
    display: block !important;
    width: 100%;
    padding: 15px;
}
.sidebar .accordion-body label {
    display: block;
    margin-right: 1rem;
    color: #FFF;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
}
.sidebar .accordion-body .form-check {
    margin-bottom: 10px;
}
.sidebar .accordion-body .search {
    position: relative;
}
.sidebar .accordion-body .search input {
    border-radius: 6px;
    border: 1px solid #FFF;
    background: #8D1832;
    padding: 8px 0 8px 38px;
    color: #FFF;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.sidebar .accordion-body .search input::placeholder{
    color: #FFF;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.5;
}
.sidebar .accordion-body .search svg {
    color: #fff;
    position: absolute;
    top: 13px;
    left: 10px;
    opacity: 0.5;
}
.sidebar .accordion-body  ul.user-artist {
    border-radius: 6px;
    background: #67131F;
    padding: 10px !important;
    margin-top: 20px;
}
.sidebar .accordion-body ul.user-artist li {
    list-style: none;
    color: #FFF;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 10px;
}
.sidebar .accordion-body ul.user-artist li img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 15px;
}
@media (max-width:991px) {
    section.fillter_nav .col-md-3 {
        width: 35%;
    }
    .sidebar .accordion-body ul.price {
        flex-wrap: wrap;
    }
    section.fillter_nav .search ul li input{width:100%;}
    section.fillter_nav .switch ul {
        display: flex;
        justify-content: flex-start;
    }
    .sidebar .accordion-body ul.price li {
        list-style: none;
        margin: 10px 0;
    }
    .sidebar .accordion-body {
        padding: 10px 0;
    }
    .sidebar .accordion-body ul.user-artist li img {
        margin: 0 auto;
        display: block;
    }
    .sidebar .accordion-item button{padding:10px !important  ;}
    .sidebar .accordion-body ul.user-artist li{text-align:center;}
    .sidebar {
        background-color: #8d1832;
        height: auto;
        width: 100%;
    }
    .sidebar .accordion {
        padding: 30px 0px;
    }
    section.collection-wrapper {
        overflow: hidden;
        padding: 0;
    }
    section.collection-wrapper section.actions {
        background: none;
        padding: 0 30px;
    }
    section.collection-banner .banner-heading a {
        top: -70px;
        left: 43px;
    }
    .switch {
        display: none;
    }
    .sidebar .accordion {
        padding: 30px 20px;
    }
}

@media (max-width:767px) {
    section.fillter_nav .search ul li input{width:100%;}
    section.fillter_nav .switch ul {
        display: flex;
        justify-content: flex-start;
    }
    .sidebar {
        background-color: #8d1832;
        height: auto;
        width: 100%;
    }
    section.collection-wrapper {
        overflow: hidden;
        padding: 0;
    }
    section.collection-wrapper section.actions {
        background: none;
        padding: 0 30px;
    }
    section.collection-banner .banner-heading a {
        top: -70px;
        left: 43px;
    }
    .switch {
        display: none;
    }
    section.fillter_nav .col-md-3 {
        order: 2;
        width:100%;
    }
    .sidebar .accordion-body {
        padding: 10px 10px;
    }
    .sidebar .accordion-body ul.user-artist li img {
        margin: 0 10px;
        display: inline-block;
    }
    .sidebar .accordion-body ul.user-artist li {
        text-align: left;
    }
}