a{text-decoration: none !important;}
button:focus{outline:0 none !important;}
.btn-check:focus+.btn-primary, .btn-primary:focus{box-shadow:none;}
/*---------header--------*/
header {
    padding: 10px 0;
    position:fixed;
    width:100%;
    z-index:9999;
}
.connect_wallet{
    margin:10px 20px !important;
}
body header li.nav-item a {
    color: #FFF !important;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    padding:0 !important;
}
header .navbar-nav li {
    padding: 0 20px;
}
#search {
    border-radius: 50px;
    border: none;
    color: #FFF;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    width: 100%;
    padding: 6px 28px 6px 40px;
    margin: 0 auto;
    outline: 0;
    height: auto;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}
.search-bar svg {
    position: absolute;
    left: 10px;
    top: 13px;
    color: #fff;
    opacity: 0.20999999344348907;
}
.search-bar {
    position: relative;
    width:35%;
}
header .wallet-btn {
    border-radius: 30px 0px 0px 30px;
    background: #8D1832;
    border: none;
    color: #FFF;
    text-align: center;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16.8px;
    padding: 10px;
    height:44px;
}
header .navbar form .dropdown button {
    border: none;
    border-radius: 0 30px 30px 0;
    background: #36454F;
    outline:0;
}
header .navbar form .dropdown button img {
    background: #fff;
    width: 32px;
    object-fit: cover;
    padding: 7px;
    border-radius: 50%;
}
header .navbar .dropdown-menu.show {
    display: block;
    border-radius: 10px;
    background: #FFF;
    left: -90px !important;
    padding:0;
}
header .navbar .dropdown-item {
    color: #000;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16.8px;
    text-align: left;
    padding: 10px;
}
header .navbar .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: transparent;
    color: #000;
}
.dropdown-item:focus, .dropdown-item:hover{background:transparent;}
/*---------banner css---------*/

body header .btn:active{
    background-color: #36454F !important;
}
.navbar-toggler-icon {
    filter: invert(1);
}
button.navbar-toggler {
    border: 1px solid#fff;
}
header ::placeholder{color:#fff !important;opacity: 0.20999999344348907 !important;}
header .navbar-expand-lg .navbar-nav a {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    padding: 10px 20px !important;
    text-align:left;
}
header .navbar .dropdown-item img {
    padding-right: 10px;
}
header.scrolling {
    background: rgb(0 0 0 / 71%);
    border-radius: 0;
    box-shadow: 0 4px 30px rgb(0 0 0);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgb(255 255 255 / 0%);
}
body .navbar-toggler:focus {
    text-decoration: none;
    outline: 0 !important;
    box-shadow: none;
}
header .wallet-btn:hover {
    background-color: #000;
}
/*------------ipad----------*/
@media (max-width:991px) {
    div#navbarSupportedContent {
        padding: 3rem 1rem;
        z-index: 9;
    }
    .navbar-collapse.collapse.show {
        transform: translate(0, 0px);
        z-index: 99;
        left: 0;
    }
    header.scrolling .navbar-collapse {
        width: 60%;
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        transition: .5s;
        background: rgb(0 0 0 / 71%);
        border-radius: 0;
        box-shadow: 0 4px 30px rgb(0 0 0);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgb(255 255 255 / 0%);
        transition: .3s;
        transform: translateX(-300px);
        height:100vh;
    }
    .navbar-collapse {
        width: 40%;
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        transition: .5s;
        background: rgb(0 0 0 / 71%);
        border-radius: 0;
        box-shadow: 0 4px 30px rgb(0 0 0);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgb(255 255 255 / 0%);
        transition: .3s;
        transform: translateX(-300px);
        height:100vh;
    }
    .search-bar {
        width: 100%;
    }
    button.navbar-toggler svg {
        color: #fff;
    }
}
/*------------mobile view-------*/
@media (max-width: 767px) {
    button.navbar-toggler svg {
        color: #fff;
    }
    .connect_wallet {
        margin: 10px 0px !important;
    }
    .search-bar {
        position: relative;
        width: 100%;
    }
    div#navbarSupportedContent {
        padding: 3rem 1rem;
        z-index: 9;
    }
    .navbar-collapse.collapse.show {
        transform: translate(0, 0px);
        z-index: 99;
        left: 0;
    }
    .navbar-collapse {
        width: 60%;
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        transition: .5s;
        background: rgb(0 0 0 / 71%);
        border-radius: 0;
        box-shadow: 0 4px 30px rgb(0 0 0);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgb(255 255 255 / 0%);
        transition: .3s;
        transform: translateX(-300px);
    }
}
