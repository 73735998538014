.buynow_modal h3 {
    margin-top: 0 !important;
    text-align: left;
}
.buynow_modal ul {
    padding: 0 !important;
    margin: 0 !important;
}
body .buynow_modal .modal-body ul li span {
    font-size: 14px !important;
    font-weight: normal !important;
}
body .buynow_modal .payment_method h5 {
    color: #FFF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    margin-top: 35px;
}
body .buynow_modal .payment_method form {
    padding: 0 !important;
}
body .buynow_modal .payment_method form span {
    display: flex;
    padding: 0 !important;
    margin-top: 20px;
}
body .buynow_modal .payment_method form span input {
    margin-right: 10px;
    cursor: pointer;
}
body .buynow_modal .payment_method form .form-check-input:checked {
    background-color: #8d1832;
    border-color: #8d1832;
}
body .buynow_modal .payment_method form span img {
    width: 22px !important;
    height: 23px !important;
    margin-right: 5px !important;
}
body .buynow_modal .buynow {
    width: 100%;
}
body .buynow_modal .modal-body img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
}
body .buynow_modal .modal-dialog {
    max-width: 700px;
}
body .buynow_modal.modal.show {
    background: #000000b5;
}
.buy_modal .modal-body ul {
    display: flex;
    justify-content: center;
}
.buy_modal .modal-body li a {
    margin: 0 !important;
    background: transparent !important;
}
.buy_modal .modal-body li a {
    margin: 0 !important;
    background: transparent !important;
    padding: 5px !important;
}
.buy_modal .modal-body li a:hover{
    background: #8d1832 !important;
}
.buy_modal .modal-body ul li {
    margin: 5px !important;
}
.buy_modal .green {
    color: #107300;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}
.buy_modal .modal-body h4 {
    color: #FFF;
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    margin:20px 0;
}
.buy_modal .modal-body img {
    height: 150px !important;
    width: 150px !important;
}


body .buy_modal .modal-body .buybtn li a {
    background: #8d1832 !important;
    padding: 15px 30px !important;
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
    border:1px solid#8d1832;
}
body .buy_modal .modal-body .buybtn li a.list_sale{
    background:none !important;
}
.buy_modal .modal-dialog {
    max-width: 500px !important;
}