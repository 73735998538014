section.collection_details {
    padding: 8rem 0 5rem;
}
section.collection_details .banner-heading a {
    color: #FFF;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    margin: 0 0 40px;
    display: block;
}
section.collection_details .banner-heading a svg {
    margin-right: 20px;
}
.text-block {
    text-align: left;
}
.text-block h3 {
    color: #FFF;
    font-family: 'Poppins';
    font-size: 24px;
    font-style: normal;
    line-height: 26px;
}
.text-block h6 {
    color: #FFF;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17.733px;
}
.text-block ul {
    display: flex;
    padding:0;
}
.text-block ul li {
    list-style: none;
    padding-right: 20px;
}
.text-block ul li span {
    color: #FFF;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.28px;
}
.text-block ul li span strong {
    margin-left: 10px;
    font-weight: 600;
}
.text-block ul li span small {
    color: #0C9500;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.28px;
    margin-left: 10px;
}
.text-block p {
    color: #F4F4F4;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: normal;
    line-height: 25.793px;
}
.text-block .progress {
    height: 5px;
}
.progressbar-data ul {
    justify-content: space-between;
    margin: 0;
}
.progressbar-data ul li span {
    color: #707A83;
}
.progressbar-data .progress-bar {
    background-color: #8d1832;
}
.detail_modal {
    border-radius: 20px;
    border: 1px solid #FFF;
    background: #000;
    padding: 20px;
    margin-top: 20px;
}
.detail_modal ul {
    justify-content: space-between;
}
.detail_modal ul li h4 {
    color: #FFF;
    font-family: 'Poppins';
    font-size: 24px;
    font-style: normal;
    line-height: 32px;
}
.detail_modal ul li span {
    color: #707A83;
}
.pop-btn li a {
    border-radius: 8px;
    border: 1px solid #8D1832;
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding: 12px;
    display: inline-block;
}
.pop-btn li a:hover{
    background-color:#8D1832;
}
.pop-btn {
    margin: 0;
    justify-content: flex-start !important;
}
.pop-btn li button {
    border-radius: 8px;
    border: 1px solid #8D1832;
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding: 12px 40px;
    display: inline-block;
    background-color: #8d1832;
}
.pop-btn li button:hover{
    border: 1px solid #8D1832;
    color: #FFF;
    background-color: transparent;
}
.common_modal .modal-dialog {
    z-index: 9999999;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    width: 100%;
    max-width: 980px;
    text-align: center;
    transform: translate(-50%, -50%) !important;
    top: 50%;
}
.common_modal .modal-dialog .modal-content {
    background: #0B0B0B;
}
.common_modal .modal-dialog .modal-content .modal-header {
    border: none;
    padding: 0;
    z-index:9999;
}
.common_modal .modal-dialog .modal-content .modal-header button.btn-close {
    background-color: #fff;
    opacity: 1;
    color: #000 !important;
    border-radius: 50%;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index:9999;
    outline: 0;
}
.btn-close:focus{box-shadow: none;}
.common_modal .modal-dialog .modal-content .modal-body img {
    width: 200px;
    height: 201px;
    border-radius: 10px;
    margin: 30px;
    object-fit: cover;
}
.common_modal .modal-dialog .modal-content .modal-body h3 {
    color: #FFF;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    margin:20px 0;
}
.common_modal .modal-dialog .modal-content .modal-body ul li {
    list-style: none;
    display: flex;
    justify-content: space-between;
    margin: 12px;
}
.common_modal .modal-dialog .modal-content .modal-body ul li span {
    text-align: left;
    color: #FFF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}
.common_modal .modal-dialog .modal-content .modal-body ul li small {
    color: #707A83;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
.common_modal .modal-dialog .modal-content .modal-body ul {
    width: 90%;
    margin: 0 auto;
}
.common_modal .modal-dialog .modal-content .modal-body a {
    border-radius: 8px;
    background: #8D1832;
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 14px;
    display: inline-block;
    margin: 40px 0;
}   
.table_box {
    border-radius: 10px;
    background: #0B0B0B;
    padding: 30px;
    text-align: left;
    min-height: 350px;
    position: relative;
}
.table_box h3 {
    color: #FFF;
    text-align: left;
    font-family: 'Poppins';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 12.948px;
}
.table_box hr {
    opacity: 0.20000000298023224;
    border-top: 1px solid #D9D9D9 !important;
}
.table_box h4 {
    color: #FFF;
    font-family: 'Poppins';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 12.948px;
}
.table_box h4 span {
    color: #FFF;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 12.948px;
    margin-right: 10px;
}
.table_box p {
    color: #FFF;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    margin: 18px 0;
}
.table_box h5 {
    color: #FFF;
    text-align: left;
    font-size: 18px;
    font-style: normal;
    font-weight: normal;
    line-height: 12.948px;
}
.table_box table {
    border: none;
    background: none;
    background-color: transparent;
}
.table_box table tr {
    border: none;
    background-color: transparent;
}
.table_box table tr td {
    background: none;
    border: none;
    color: #FFF;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.51px;
    padding: 15px 15px;
}
.table_box table tr th {
    background: #8D1832;
    border: none;
    color: #FFF;
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 15px 15px;
}
.table_box table tbody tr {
    border-bottom: 0.956px solid rgba(255, 255, 255, 0.12);
}
.table-heading {
    padding: 30px 30px 10px;
}
.table_box table tbody tr:last-child{
    border:none;
}
.nolist {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.card-slider.position-relative {
    margin: 10px;
}
.card-caption p {
    margin-bottom: 0;
}
.card-caption {
    bottom: 10px;
    width:90%;
}
section.collection_slider {
    padding: 5rem 0;
}
.img-caption {
    border-radius: 30px 0px 0px 30px;
    border: 2px solid #8D1832;
    background: rgba(255, 255, 255, 0.20);
    -webkit-backdrop-filter: blur(104.5px);
    backdrop-filter: blur(104.5px);
    width: 150px;
    height: 50px;
    position: absolute;
    top: 30px;
    right: 0;
    text-align: left;
    padding: 5px 20px;
}
.img-caption p {
    color: #FFF;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin: 0;
}
.img-caption h3 {
    color: #FFF;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}
body .make_offer .modal-dialog {
    max-width: 850px;
}
.make_offer .modal-dialog .modal-header {
    text-align: center;
    justify-content: center;
}
body .make_offer .modal-dialog .modal-title.h4 {
    color: #FFF;
    font-family: 'Poppins';
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    display: block;
    padding: 0;
}
.common_modal .modal-dialog form {
    padding: 20px;
}
.make_offer form input.form-control {
    height: auto;
    border-radius: 20px;
    border: 1px solid #FFF;
    background: #000;
    padding: 13px;
}
.common_modal .modal-dialog form input.form-check-input {
    padding: 0;
    height: 20px !important;
    width: 20px;
}
.common_modal .modal-dialog form span {
    color: #FFF;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    line-height: 22px;
    padding-left: 20px;
}
.common_modal .modal-dialog img {
    height: auto !important;
    width: 100%;
    margin: 0 !important;
}
button.makeoffer:hover{
    background: #8D1832;
}
button.makeoffer {
    border-radius: 8px;
    background: #8D1832;
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    border: none;
    padding: 14px;
    display: block;
    width: 100%;
    margin: 20px 0;
}
body button.makeofferbtn {
    border: 1px solid #8D1832;
    background-color: transparent;
}
body button.makeofferbtn:hover{
    background-color: #8D1832;
}

.place_bid.modal .green {
    color: #107300;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}
.place_bid.modal span {
    color: #FFF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin: 30px 0 10px;
    display: block;
}
.place_bid.modal span img {
    width: 40px !important;
    margin-right: 15px !important;
}
.place_bid.modal p {
    text-align: center;
}
.common_modal .modal-dialog .modal-content .modal-body button.btn.btn-primary {
    border-radius: 8px;
    background: #8D1832;
    border: none;
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    line-height: normal;
    padding: 12px 25px;
    margin: 20px 0;
}
.mint-flex {
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #8D1832;
    padding: 5px;
}
body .mint-flex button {
    background-color: #2e2d2d;
    border: none;
    padding: 0;
    width: 35px;
    height: 35px;
    margin: 0 10px;
}
body .mint-flex button:hover{
    background-color: #2e2d2d;
    border:none;
}
body .mint-flex  p {
    margin: 0;
}
.minting-modal .modal-dialog {
    max-width: 600px;
}
.common_modal.place_bid ul {
    display: flex;
    justify-content: center;
}

@media (max-width:991px) {
    .pop-btn li a{
        font-size:14px;
    }
}

@media (max-width:767px) {
    .table_box {
        overflow-x: scroll;
        margin-top: 20px;
    }
    .pop-btn li a{font-size:12px;}
    .common_modal .modal-dialog .modal-content .modal-body ul {
        width: 100%;
        margin: 0 auto;
    }
    .common_modal .modal-dialog{
        width: 90%;
    }
    .text-block ul li span strong {
        margin-left: 0;
    }
    .text-block {
        margin-top: 20px;
    }
}