section.banner .carousel-caption{
position:inherit !important;
text-align: left;
}
section.banner .carousel-caption h3 {
    color: #FFF;
    font-family: Poppins;
    font-size: 50px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px;
    letter-spacing: -1px;
}
section.banner .carousel-caption p {
    color: #A2A2A2;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}
section.banner .carousel-caption ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
}
section.banner .carousel-caption ul li{
    list-style:none;
    width:30%;
}
section.banner .carousel-caption ul li h4 {
    color: #FFF;
    font-family: 'Poppins';
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
}
section.banner .carousel-caption ul li span {
    color: #FFF;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16.8px;
}
section.banner .carousel-caption ul li a {
    border-radius: 8px;
    border: 1px solid #8D1832;
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 10px 30px;
    display: inline-block;
    margin-top: 30px;
}
.banner .view_btn {
    background-color: #8D1832;
}
.banner .carousel-control-next{display: none;}
.banner .carousel-control-prev{display: none;}
.banner .carousel-indicators{bottom:-100px;}
.banner-bg {
    width: 100%;
    position: absolute;
    top: 0;
    z-index: -1;
    height: 100vh;
    object-fit: cover;
    left:0;
}
section.banner {
    padding: 10rem 0 10rem;
    overflow: hidden;
    position: relative;
}
.banner .carousel-indicators .active {
    opacity: 1;
    background-color: #8d1832;
}
section.sell_now {
    padding: 5rem 0;
}
section.sell_now h3 {
    color: #FFF;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    margin: 0 0 50px;
}
section.sell_now .sell_block {
    border-radius: 10px;
    background: #36454F;
    text-align: left;
    padding: 30px;
}
section.sell_now .sell_block span {
    color: #FFF;
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    padding-left: 15px;
}
section.sell_now .sell_block p {
    color: #A2A2A2;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    margin-top: 20px;
    margin-bottom: 0;
}


/*-------slick slider css---------*/
.itemSlider .card-slider {
    border-radius: 8px;
    background: #FFF;
    margin: 10px;
    padding: 20px;
    text-align:left;
    position: relative;
}
section.itemSlider {
    padding: 0rem 0 5rem ;
}
.itemSlider .card-slider span {
    display: inline-block;
    text-align: left;
}
.itemSlider .card-slider span img{margin-right:10px;display: inline-block;}
.itemSlider .card-slider span {
    display: inline-block;
    text-align: left;
    margin-bottom: 10px;
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}
.itemSlider .main_img img {
    display: block;
    width: 100%;
    height: 231px;
    object-fit: cover;
    border-radius: 10px;
}
.itemSlider .main_img a {
    border-radius: 500px;
    background: #FFF;
    width: 60px;
    height: 30px;
    color: #000;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    position: absolute;
    top: 85px;
    right: 30px;
}
.itemSlider .main_img a svg {
    color: #8D1832;
    margin: 10px;
}
.itemSlider .slider_data h6 {
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    margin: 10px 0 0;
}
.itemSlider .slider_data small {
    color: #4F4F4F;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    display: block;
}
.itemSlider .slider_data .view_all {
    color: #8D1832;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    text-decoration-line: underline !important;
    display: inline-block;
    margin-top: 20px;
}
.itemSlider .slider_data p {
    margin: 4px 0 0;
    color: #4F4F4F;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
}
.itemSlider .slider_data .Buy.Now {
    border-radius: 8px;
    background: #8D1832;
    color: #FFF;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    padding: 5px 10px;
    display: inline-block;
    margin: 10px 0;
    position: absolute;
    right: 15px;
    bottom:0;
}
.itemSlider .slider_data .Buy_Now {
    border-radius: 8px;
    background: #8D1832;
    color: #FFF;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    padding: 5px 10px;
    display: inline-block;
    margin: 10px 0;
    position: absolute;
    right: 15px;
    bottom:0;
    border:none;
}
.main-heading {
    text-align: left;
}
.main-heading h3 {
    color: #FFF;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    margin: 10px 10px 30px;
}
.itemSlider .slider_data p strong {
    color: #107300;
    font-weight: normal;
    padding-left: 10px;
}
.itemSlider .slick-next:before {
    content: '→';
    color: #fff;
    font-size: 30px;
}
.itemSlider .slick-prev:before {
    color: #fff;
    font-size: 30px;
}
.itemSlider .slick-dots{display:none !important;}

/*---------artist slider-------*/
section.artistSlider .card-slider {
    border-radius: 1007px;
    background: #FFF;
    padding: 10px;
}
.artist_data.artist_data img {
    display: inline-block;
}
section.artistSlider .card-slider {
    border-radius: 1007px;
    background: #FFF;
    padding: 10px;
    margin: 10px;
}
.artist_data.artist_data {
    text-align: left;
}
.artist_data.artist_data span {
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    padding-left: 10px;
}
section.artistSlider {
    padding-bottom: 4rem;
}
section.artistSlider .slick-prev {
    right: 90px;
    top: -32px;
    left:inherit;
}
section.artistSlider .slick-next {
    right: 40px;
    top: -32px;
}
section.artistSlider .slick-prev:before {
    font-size: 30px;
}
section.artistSlider .slick-next:before {
    font-size: 30px;
}

/*-------------collecctions list------*/
.collection-list {
    text-align: left;
}
section.collection .collection-list ul {
    border: none;
    display: flex;
}
section.collection .collection-list ul .react-tabs__tab--selected {
    border-radius: 10px 0px 0px 10px !important;
    background: #8D1832 !important;
    color: #FFF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    padding: 12px;
    border:none !important;
}
section.collection .collection-list ul li{list-style:none;}
section.collection .collection-list ul .untabactive{
    border-radius: 10px 0px 0px 10px !important;
    background: #480F0F;
    color: #FFF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    padding: 12px;
    border:none !important;
    cursor: pointer !important;
    outline:0;
}
body section.collection .collection-list ul .react-tabs__tab {
    display: inline-block;
    position: relative;
    list-style: none;
    cursor: pointer;
    border-radius: 0px 10px 10px 0px;
    background: #480F0F;
    color: #FFF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    padding: 12px;
    border: none !important;
}
body section.collection  .react-tabs__tab:focus:after{display:none;}
body section.collection .tabactive {
    border-radius: 0px 10px 10px 0px;
    background: #480F0F;
    color: #fff;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    padding: 12px;
    border: none !important;
    cursor: pointer !important;
    outline:0;
}
section.collection .collection-list ul li.tabactive.react-tabs__tab--selected {
    outline: 0;
    border-radius: 0 10px 10px 0px !important;
}
.table_data ul {
    padding: 0;
    margin: 30px 0;
    display: flex;
}
.table_data table {
    border: none;
    background: transparent;
}
.table_data table tr{
    border: none;
    background: transparent;
}
.table_data table tr td{
    border: none;
    background: transparent;
    color: #FFF;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    vertical-align: middle;
}
.table_data table tr th {
    border: none;
    background: transparent;
    color: #FFF;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}
.table_data {
    margin-top: 30px;
}
.table_data table tr td img {
    width: 53px;
    height: 54px;
    object-fit: cover;
    border-radius: 8px;
    margin-right: 20px;
}
.table_head {
    padding-left: 6rem !important;
}
.day-data.text-end {
    justify-content: flex-end;
    display: flex;
}
.day-data.text-end li button {
    border-radius: 0;
    background: #480F0F;
    border: none;
    color: #FFF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    padding: 10px;
}
.day-data.text-end .active button {
    border-radius: 8px 0 0 8px;
    background: #8D1832;
}
.day-data.text-end li.border-right button {
    border-radius: 0 8px 8px 0;
}
.day-data.text-end li a {
    color: #FFF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    padding: 11px;
    display: inline-block;
}
/*------------actions css ------------*/
section.actions .action_card {
    position: relative;
}
section.actions .action_card .img-caption {
    border-radius: 30px 0px 0px 30px;
    border: 2px solid #8D1832;
    background: rgba(255, 255, 255, 0.20);
    backdrop-filter: blur(104.5px);
    width: 150px;
    height: 50px;
    position: absolute;
    top: 30px;
    right: 0;
    text-align: left;
    padding:5px 20px;
}
section.actions .action_card .img-caption p {
    color: #FFF;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin: 0;
}
section.actions .action_card .img-caption h3 {
    color: #FFF;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}
section.actions .card-caption {
    border-radius: 4px;
    background: #FFF;
    padding: 10px;
    position: absolute;
    width: 90%;
    bottom: 20px;
    left: 16px;
    text-align: left;
}
section.actions .card-caption img {
    position: absolute;
    top: -25px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    object-fit: cover;
}
section.actions .card-caption h4 {
    color: #000;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    margin-top: 20px;
}
section.actions .card-caption p {
    color: #A2A2A2;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
}
section.actions .card-caption p strong {
    color: #000;
    margin-left: 20px;
}
section.actions .card-caption ul {
    padding: 0;
    display: flex;
    justify-content: space-between;
    margin: 10px 0 0;
}
section.actions .card-caption ul li {
    list-style: none;
    color: #000;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
}
section.actions .card-caption ul li a {
    border-radius: 8px;
    background: #8D1832;
    color: #FFF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    padding: 10px;
    display: inline-block;
}
section.banner .carousel-indicators{align-items: center;}
section.actions .card-caption ul li svg {
    margin-right: 10px;
}
section.actions .action_card {
    position: relative;
    margin-bottom: 30px;
}
section.actions .View_all {
    color: #FFF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}
section.actions {
    padding: 3rem 0;
    background: #36454F;
}
.single-card-img img {
    height: 400px;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
}
.single-card-img {
    height: 400px;
    overflow: hidden;
    border-radius:10px;
}
.single-card-img:hover .card-effact {
    display: none;
}
.card-effact2{display:none;}
.single-card-img:hover .card-effact2{display:block;}
section.itemSlider.popularcollections {
    padding: 4em 0;
}
section.itemSlider.Categories-data .card-slider {
    padding: 0;
    border-radius: 12px;
}
section.itemSlider.Categories-data .card-slider img {
    border-radius: 10px 10px 0 0;
}
section.itemSlider.Categories-data .card-slider h3 {
    color: #000;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    padding: 20px;
}

section.itemSlider .slick-track{
    margin-left:0;
}
/* .popularcollections .card-slider {
    width: 100% !important;
    max-width:300px;
    margin-left: 15px;
} */


/*---------laptop view--------*/
@media (max-width: 1440px) {
    .banner-bg {
        height: 100%;
    }
    .artist_data.artist_data span{font-size:14px;}
}
@media (max-width: 1366px) {
    section.banner .carousel-caption ul li a {
        padding: 10px 25px;
    }
}


/*-----------ipad------------*/
@media (max-width:991px) {
    .table_data table {
        border: none;
        background: transparent;
        width: 1000px;
    }
    .react-tabs__tab-panel--selected {
        overflow-x: scroll;
    }
    section.sell_now .sell_block {
        margin-bottom: 20px;
    }
    section.banner .carousel-caption {
        padding-top: 0;
        margin: 0 !important;
    }
    section.banner .carousel-caption h3 {
        font-size: 30px;
        line-height: 40px;
    }
    section.banner .carousel-caption ul li {
        list-style: none;
        width: auto;
        padding: 6px;
    }
    section.banner .carousel-caption ul li h4{font-size:24px;}
    section.banner {
        padding: 7rem 0 5rem;
    }
    section.banner .carousel-caption ul li a{
        margin-top: 0;
    }
    .banner .carousel-indicators {
        bottom: -50px;
    }
    .itemSlider .slick-next {
        right: -13px;
    }
}
/*---------mobile view---------*/
@media (max-width: 767px) {
    .slider_data .col-md-6 {
        width: 50%;
    }
    .slider-img {
        margin-bottom: 40px;
    }
    section.sell_now {
        padding: 5rem 0 2rem;
    }
    section.itemSlider .main-heading h3 {
        margin: 0 30px;
    }
    section.actions .col-md-6 {
        width: 50%;
    }
    section.actions .col-lg-3.col-md-6.col-sm-12{ width: 100%;}
    .day-data.text-end {
        justify-content: flex-start;
        padding: 0;
    }
    section.collection .collection-list ul {
        padding: 0;
    }
    .itemSlider .card-slider {
        margin: 30px;
    }
    .itemSlider .slick-next {
        right: 3px;
    }
    .itemSlider .slick-prev {
        left: -4px;
    }
    section.banner .carousel-caption p {
        font-size: 15px;
        line-height: 25px;
    }
    .banner .carousel-indicators {
        bottom: -30px;
    }
    section.banner {
        padding: 6rem 0 1rem;
    }
    .banner-bg {
        height: 100%;
    }
    section.banner .carousel-caption {
        margin-top: 0 !important;
    }
    section.banner .carousel-caption h3 {
        font-size: 24px;
        line-height: 40px;
        margin-top: 20px;
    }
    section.banner .carousel-caption ul li h4 {
        font-size: 18px;
        margin-bottom: 0;
    }
    section.banner .carousel-caption ul li {
        list-style: none;
        width: auto;
        padding: 0 8px;
    }
    section.banner .carousel-caption ul li a{margin-top:0;padding:10px 12px;}
}