section.Categories-data {
    background: #242424;
    padding: 10rem 0 5rem;
}
.itemSlider .main_img img {
    height: 300px;
    position: relative;
}
section.itemSlider.home_categories .card-slider {
    padding: 0;
    background: transparent;
}
body section.itemSlider.home_categories .card-slider a h3 {
    display: block;
    width: 100%;
    color: #000;
    font-family: Poppins;
    font-size: 17px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    margin: 0;
}
section.itemSlider.home_categories .card-slider img {
    border-radius: 10px 10px 0 0;
}
body section.itemSlider.home_categories .card-slider a {
    position: inherit !important;
    display: block;
    width: 100%;
    height: auto;
    padding: 10px;
    border-radius: 0 0 10px 10px;
}
section.itemSlider.Categories-data .card-slider a {
    color: #fff;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: normal;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px 10px 0px 0px;
    background: #36454F;
    padding: 0;
    width: 150px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    display: block;
    top: inherit;
}
section.swipper_slider {
    padding: 5rem 0 0;
}
.main-heading h3 img {
    margin-right: 20px;
}
section.itemSlider.Categories-data .card-slider a:hover{
    background: #8d1832;
}
.View_all {
    color: #FFF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    line-height: 18px;
}
section.swipper_slider .slick-slide img {
    display: block;
    width: 100%;
}
section.swipper_slider .slick-slide.slick-active {
    margin: 10px;
}
section.swipper_slider .swiper-slide.swiper-slide-active {
    /* transform: scaleY(1.5); */
    width: 600px !important;
    position: relative;
    z-index: 9999;
    left: -50px;
    border-radius: 10px;
}
/* section.swipper_slider .swiper-wrapper {
    right: 35%;
} */
section.swipper_slider .swiper-wrapper{
    align-items: center;
}
section.swipper_slider .swiper-slide.swiper-slide-next {
    position: relative;
    left: -182px;
    overflow: hidden;
}
.card-caption {
    border-radius: 4px;
    background: #FFF;
    padding: 10px;
    position: absolute;
    width: 93%;
    bottom: 50px;
    left: 16px;
    text-align: left;
}
.card-caption img {
    position: absolute;
    top: -25px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    object-fit: cover;
}

.card-caption h4 {
    color: #000;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    margin-top: 20px;
}
.card-caption p {
    color: #A2A2A2;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
}
.card-caption p strong {
    color: #000;
    margin-left: 20px;
}
.card-caption ul {
    padding: 0;
    display: flex;
    justify-content: space-between;
}
.card-caption ul li {
    list-style: none;
    color: #000;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
}
.card-caption ul li a {
    border-radius: 8px;
    background: #8D1832;
    color: #FFF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    padding: 10px;
}
.swiper-slide-prev{
    right:-10% !important;
    transform: translateY(0%) scale(.75) rotateY(10deg) !important;
}
.swiper-slide-next{
    left:-10% !important;
    transform: translateY(0%) scale(.75) rotateY(-10deg) !important;
}
section.swipper_slider .swiper-slide.swiper-slide-next .card-caption {
    left: 150px;
}
section.swipper_slider .card-caption ul {
    justify-content: flex-end;
}
@media (max-width:991px) {
    section.swipper_slider .swiper-slide.swiper-slide-active {
        left: -100px;
        width: 440px !important;
    }
}

@media (max-width:767px) {
    section.itemSlider.Categories-data .card-slider {
        margin: 10px;
    }
    body .itemSlider .card-slider {
        margin: 30px;
    }
    section.swipper_slider .swiper-slide.swiper-slide-active {
        transform: initial;
        width: 378px !important;
        position: relative;
        z-index: 9999;
        left: -104px;
        border-radius: 10px;
        margin: 0 -21px;
    }
    .main-heading h3{font-size:16px;margin:4px 10px 26px;}
    section.swipper_slider .col-md-6.main-heading {
        width: 70%;
    }
    section.swipper_slider .col-md-6.text-end{width:30%;}
}