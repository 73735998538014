footer {
  background-color: #8d1832;
  padding:4rem 0 3rem;
  text-align: left;
}
footer .footerTopBar h5 {
  color: #FFF;
  font-family: Poppins;
  font-size: 15.134px;
  font-style: normal;
  font-weight: 600;
  line-height: 22.701px;
}
footer .footerTopBar p {
  color: #FBFDFF;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
footer .footerTopBar input#search {
  width: 50%;
  margin: 0;
  display: inline-block;
  border-radius: 10px 0 0 10px;
  border: 0.757px solid #E5E8EB;
  background: #FFF;
  padding: 8px;
  outline: 0;
  color: #000;
}
footer .footerTopBar button {
  border-radius: 0px 10px 10px 0px;
  background: #000;
  border: none;
  color: #FFF;
  text-align: center;
  font-family: 'Poppins';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 35.647px;
  letter-spacing: 0.121px;
  outline: 0;
  padding: 7px;
}
footer .footerTopBar button:hover {
  background-color: #8d1832;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}
footer .footerTopBar ul {
  display: flex;
  justify-content: flex-end;
}
footer .footerTopBar ul li {
  list-style: none;
  padding: 10px;
}
footer .footerTopBar ul li span {
  color: #FFF;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}
footer .footerMenu p {
  color: #FBFDFF;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  margin-top: 20px;
  margin-bottom: 0;
}
footer .footerMenu h4 {
  color: #FFF;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 13.318px;
  padding-left: 0;
}
footer .footerMenu ul {
  padding-left: 0;
  margin-top:20px;
}
footer .footerMenu ul li{
  list-style: none;
}
footer .footerMenu ul li a {
  color: #FBFDFF;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}
footer .footerMenu ul li a:hover {
  opacity: 0.6;
}
footer .copyright p {
  color: #FFF;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
footer .footerTopBar ul li a:hover svg {
  opacity: 0.5;
}
/*-----------ipad------------*/
@media (max-width:991px) {
  .footerMenu .col-md-5 {
    width: 100%;
    max-width: 100%;
    flex: 100%;
}
  body .footerMenu .col {
    width: 25%;
    max-width: 25%;
    flex: 25%;
    margin-top: 2rem;
}
footer .footerTopBar input#search {
  width: 75%;
}
}
/*------------mobile view-------*/
@media (max-width: 767px) {
  .footerMenu .col-md-5 {
    width: 100%;
    max-width: 100%;
    flex: 100%;
}
  footer .footerMenu .col {
    width: 50%;
    max-width: 50%;
    flex: 50%;
    margin-top: 2rem;
}
footer .footerTopBar ul {
  justify-content: flex-start;
  padding: 0;
}
footer .footerTopBar input#search {
  width: 65%;
}
.footerMenu {
  margin-top: 1rem !important;
}
section.sell_now .sell_block {
  margin-bottom: 20px;
}
}